<template>
	<div>
		<page-title></page-title>
		<b-card class="mt-1 position-relative" no-body style="height: calc(100vh - 12rem)">
			<b-embed :src="src" allowfullscreen class="rounded" type="iframe"></b-embed>
		</b-card>
	</div>
</template>

<script>
	import { computed, getCurrentInstance, reactive, toRefs } from "@vue/composition-api";
	import PageTitle from "@/views/layout/PageTitle";
	import { iframeSrc } from "@/libs/utils/iframeSrc";

	export default {
		name: "ProjectIframe",
		components: {
			PageTitle,
		},
		setup() {
			const instance = getCurrentInstance();
			const event = reactive({
				src: computed(() => {
					let src = instance.proxy.$route.params.src;
					src = new Buffer(src, "base64").toString();
					src = iframeSrc(src);
					return src;
				}),
			});

			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
